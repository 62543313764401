<template>
  <navbar />
  <page-title title="Create Customer" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <form @submit.prevent="submit">
      <div class="shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-5 gap-6">
            <div class="col-span-6 sm:col-span-3">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <input
                v-model="form.name"
                type="text"
                name="name"
                id="first-name"
                autocomplete="given-name"
                class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <span class="text-red-500 text-sm block mt-1" v-if="errors.name">
                {{ errors.name[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="phone" class="block text-sm font-medium text-gray-700"
                >Phone</label
              >
              <input
                v-model="form.phone"
                type="text"
                name="phone"
                id="phone"
                class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <span class="text-red-500 text-sm block mt-1" v-if="errors.phone">
                {{ errors.phone[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="term_of_payment"
                class="block text-sm font-medium text-gray-700"
                >Term of Payment</label
              >

              <select
                v-model="form.term_of_payment"
                id="term_of_payment"
                name="term_of_payment"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">-- Select --</option>
                <option value="Cash / Transfer">Cash / Transfer</option>
                <option value="1 Week">1 Week</option>
                <option value="2 Weeks">2 Weeks</option>
                <option value="30 Days">30 Days</option>
              </select>
              <span
                class="text-red-500 text-sm block mt-1"
                v-if="errors.term_of_payment"
              >
                {{ errors.term_of_payment[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="type" class="block text-sm font-medium text-gray-700"
                >Type</label
              >
              <select
                v-model="form.type"
                id="type"
                name="type"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="outlet">Outlet</option>
                <option value="kantor">Kantor</option>
              </select>
              <span class="text-red-500 text-sm block mt-1" v-if="errors.type">
                {{ errors.type[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
                >Address</label
              >
              <input
                v-model="form.address"
                type="text"
                name="address"
                id="address"
                class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <span
                class="text-red-500 text-sm block mt-1"
                v-if="errors.address"
              >
                {{ errors.address[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="area" class="block text-sm font-medium text-gray-700"
                >Area</label
              >
              <input
                v-model="form.area"
                type="text"
                name="area"
                id="area"
                class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <span class="text-red-500 text-sm block mt-1" v-if="errors.area">
                {{ errors.area[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="sales_name"
                class="block text-sm font-medium text-gray-700"
                >Sales Name</label
              >
              <select
                v-model="form.sales_name"
                id="sales_name"
                name="sales_name"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">-- Select --</option>
                <option value="Widya">Widya</option>
                <option value="Manik">Manik</option>
                <option value="Santi">Santi</option>
                <option value="Shela">Shela</option>
                <option value="Nita">Nita</option>
                <option value="Kantor">Kantor</option>
              </select>
              <span
                class="text-red-500 text-sm block mt-1"
                v-if="errors.sales_name"
              >
                {{ errors.sales_name[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="type" class="block text-sm font-medium text-gray-700"
                >Invoice Type</label
              >
              <select
                v-model="form.invoice_type"
                id="type"
                name="type"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">-- Select --</option>
                <option value="Copy">Copy</option>
                <option value="Putih">Putih</option>
              </select>
              <span
                class="text-red-500 text-sm block mt-1"
                v-if="errors.invoice_type"
              >
                {{ errors.invoice_type[0] }}
              </span>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-left sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <div v-if="loading" class="btn-spinner mt-1 mr-2" />
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import PageTitle from "@/components/PageTitle.vue";
import { mapState } from "vuex";

export default {
  name: "CreateCustomer",
  components: {
    Navbar,
    PageTitle,
  },
  computed: mapState("customers", {
    form: (state) => state.form,
    errors: (state) => state.errors,
    loading: (state) => state.loading,
  }),
  mounted() {
    this.resetForm();
  },
  methods: {
    resetForm() {
      this.$store.dispatch("customers/resetCustomerForm");
    },
    submit() {
      this.$store.dispatch("customers/storeCustomer", this.form);
    },
  },
};
</script>
